import { template as template_b2a450f9215b41c1b01eff2bf3d0f4b4 } from "@ember/template-compiler";
const FKText = template_b2a450f9215b41c1b01eff2bf3d0f4b4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
