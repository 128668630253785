import { template as template_e00100eb15bb41c7b4cd5369a5f9ba08 } from "@ember/template-compiler";
import NotificationsTracking from "discourse/components/notifications-tracking";
const GroupNotificationsTracking = template_e00100eb15bb41c7b4cd5369a5f9ba08(`
  <NotificationsTracking
    @onChange={{@onChange}}
    @levelId={{@levelId}}
    @showCaret={{false}}
    @showFullTitle={{false}}
    @prefix="groups.notifications"
    class="group-notifications-tracking"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GroupNotificationsTracking;
