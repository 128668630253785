import { template as template_6f1a560dfbb348d9b5ae5efedca9893a } from "@ember/template-compiler";
const WelcomeHeader = template_6f1a560dfbb348d9b5ae5efedca9893a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
